import { DivisionsTree } from "@gettactic/api";

export function hasFilterInTreeRecursive(tree: DivisionsTree, filter: string) {
  if (!filter) {
    return true;
  }

  // if division name has a filter
  if (tree.name?.toLowerCase().includes(filter.toLowerCase())) {
    return true;
  }

  // if any of offices have a filter in a name
  if (
    tree.offices?.some((office) =>
      office.name.toLowerCase().includes(filter.toLowerCase())
    )
  ) {
    return true;
  }

  for (const child of tree.children) {
    if (hasFilterInTreeRecursive(child, filter)) {
      return true;
    }
  }

  return false;
}

export const OFFICES_LABEL = "Offices";
export const TOP_LEVEL = 1;

export const LocationVariants = {
  office: "office",
  dir: "dir",
  OOO: "OOO",
  remote: "remote",
} as const;

export type LocationTypes =
  (typeof LocationVariants)[keyof typeof LocationVariants];

  