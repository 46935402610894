import React from "react";
export const HomeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.36242 2.17663C8.04583 1.49321 9.15387 1.49321 9.83729 2.17663L14.8373 7.17663C15.1655 7.50481 15.3499 7.94993 15.3499 8.41406V12.9998C15.3499 13.9663 14.5664 14.7498 13.5999 14.7498H3.59985C2.63335 14.7498 1.84985 13.9663 1.84985 12.9998V8.41406C1.84985 7.94993 2.03423 7.50481 2.36242 7.17663L7.36242 2.17663ZM8.77663 3.23729C8.679 3.13965 8.52071 3.13965 8.42308 3.23729L3.42308 8.23729C3.37619 8.28417 3.34985 8.34776 3.34985 8.41406V12.9998C3.34985 13.1379 3.46178 13.2498 3.59985 13.2498H13.5999C13.7379 13.2498 13.8499 13.1379 13.8499 12.9998V8.41406C13.8499 8.34776 13.8235 8.28417 13.7766 8.23729L8.77663 3.23729Z"
      fill="#778086"
    />
  </svg>
);
