import React, { createContext, useState, ReactNode, useContext } from 'react';

interface FilterContextValue {
  filter: string;
  setFilter: (filter: string) => void;
  resetFilter: () => void;
}

export const FilterContext = createContext<FilterContextValue>({
  filter: '',
  setFilter: () => {},
  resetFilter: () => {},
});

interface FilterProviderProps {
  children: ReactNode;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({
  children,
}) => {
  const [filter, setFilter] = useState<string>('');

  const resetFilter = () => {
    setFilter('');
  };

  return (
    <FilterContext.Provider
      value={{
        filter,
        setFilter,
        resetFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error(
      'useFilterContext must be used within a FilterProvider'
    );
  }
  return context;
};