import { cn } from '@gettactic/helpers';
import RedbullAucklandIcon from '../../icons/RedBull/RedbullAucklandIcon';
import RedbullBratislavaIcon from '../../icons/RedBull/RedbullBratislavaIcon';
import RedbullBudapestIcon from '../../icons/RedBull/RedbullBudapestIcon';
import RedbullElsbethenIcon from '../../icons/RedBull/RedbullElsbethenIcon';
import RedbullFuschlIcon from '../../icons/RedBull/RedbullFuschlIcon';
import RedbullGenericIcon from '../../icons/RedBull/RedbullGenericIcon';
import RedbullHomeIcon from '../../icons/RedBull/RedbullHomeIcon';
import RedbullKrieauIcon from '../../icons/RedBull/RedbullKrieauIcon';
import RedbullMunichIcon from '../../icons/RedBull/RedbullMunichIcon';
import RedbullNoIcon from '../../icons/RedBull/RedbullNoIcon';
import RedbullViennaIcon from '../../icons/RedBull/RedbullViennaIcon';
import RedbullWalsIcon from '../../icons/RedBull/RedbullWalsIcon';
import RedbulloooIcon from '../../icons/RedBull/RedbulloooIcon';

const RedbullRegionIcons = Object.freeze({
  Auckland: RedbullAucklandIcon,
  Bratislava: RedbullBratislavaIcon,
  Budapest: RedbullBudapestIcon,
  Elsbethen: RedbullElsbethenIcon,
  Fuschl: RedbullFuschlIcon,
  Generic: RedbullGenericIcon,
  Krieau: RedbullKrieauIcon,
  Munich: RedbullMunichIcon,
  Vienna: RedbullViennaIcon,
  Wals: RedbullWalsIcon,
  no: RedbullNoIcon,
  ooo: RedbulloooIcon,
  home: RedbullHomeIcon
});

export type RedBullIconRegionType = keyof typeof RedbullRegionIcons;

export function RedbullOfficeIcon({
  className = '',
  region = 'Generic'
}: { className?: string; region?: RedBullIconRegionType }) {
  const Icon = RedbullRegionIcons[region] ?? RedbullRegionIcons.Generic;
  return (
    <div className={cn('flex items-center justify-center', className)}>
      <Icon />
    </div>
  );
}
