import { classNames } from '@/lib/classNames';
import { RedbullRegionIcons } from "@gettactic/components/src/icons/TacticIcons/statusIcons";

export type RedBullIconRegionType = keyof typeof RedbullRegionIcons;

export function RedbullOfficeIcon({
  className = '',
  region = 'Generic'
}: { className?: string; region?: RedBullIconRegionType }) {
  const Icon = RedbullRegionIcons[region] ?? RedbullRegionIcons.Generic;
  return (
    <div className={classNames('flex items-center justify-center', className)}>
      <Icon />
    </div>
  );
}
