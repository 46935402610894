import RedbullAucklandIcon from '../RedBull/RedbullAucklandIcon';
import RedbullBratislavaIcon from '../RedBull/RedbullBratislavaIcon';
import RedbullBudapestIcon from '../RedBull/RedbullBudapestIcon';
import RedbullElsbethenIcon from '../RedBull/RedbullElsbethenIcon';
import RedbullFuschlIcon from '../RedBull/RedbullFuschlIcon';
import RedbullGenericIcon from '../RedBull/RedbullGenericIcon';
import RedbullHomeIcon from '../RedBull/RedbullHomeIcon';
import RedbullKrieauIcon from '../RedBull/RedbullKrieauIcon';
import RedbullMunichIcon from '../RedBull/RedbullMunichIcon';
import RedbullNoIcon from '../RedBull/RedbullNoIcon';
import RedbullViennaIcon from '../RedBull/RedbullViennaIcon';
import RedbullWalsIcon from '../RedBull/RedbullWalsIcon';
import RedbulloooIcon from '../RedBull/RedbulloooIcon';

import { RedbullOfficeIcon } from '../../components/office/RedbullOfficeIcon';
import { CircleCheckEmpty } from './CircleCheckEmpty';
import { CircleCheckFilled } from './CircleCheckFilled';
import { CrossIcon } from './CrossIcon';
import { HomeIcon } from './HomeIcon';
import { MinusIcon } from './MinusIcon';

export const RedbullRegionIcons = Object.freeze({
    Auckland: RedbullAucklandIcon,
    Bratislava: RedbullBratislavaIcon,
    Budapest: RedbullBudapestIcon,
    Elsbethen: RedbullElsbethenIcon,
    Fuschl: RedbullFuschlIcon,
    Generic: RedbullGenericIcon,
    Krieau: RedbullKrieauIcon,
    Munich: RedbullMunichIcon,
    Vienna: RedbullViennaIcon,
    Wals: RedbullWalsIcon,
    no: RedbullNoIcon,
    ooo: RedbulloooIcon,
    home: RedbullHomeIcon
  });

export const AvailableStatus = {
    remote: 'remote',
    ooo: 'ooo',
    unknown: 'unknown',
    reservation: 'reservation',
    inOffice: 'inOffice'
  } as const;
  
  type RbRegion =
    | 'ooo'
    | 'home'
    | 'no'
    | keyof typeof RedbullRegionIcons
    | undefined;
  
  export const RBStatusIcon = {
    [AvailableStatus.remote]: () => <RedbullOfficeIcon region={'home'} />,
    [AvailableStatus.ooo]: () => <RedbullOfficeIcon region={'ooo'} />,
    [AvailableStatus.unknown]: () => <RedbullOfficeIcon region={'no'} />,
    [AvailableStatus.reservation]: ({
      region
    }: {
      region: RbRegion;
    }) => <RedbullOfficeIcon region={region} />,
    [AvailableStatus.inOffice]: ({
      region
    }: {
      region: RbRegion;
    }) => <RedbullOfficeIcon region={region} />
  };
  
 export const OfficeIcon = {
    [AvailableStatus.remote]: HomeIcon,
    [AvailableStatus.ooo]: CrossIcon,
    [AvailableStatus.unknown]: MinusIcon,
    [AvailableStatus.reservation]: CircleCheckEmpty,
    [AvailableStatus.inOffice]: CircleCheckFilled
  };