import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle
} from 'react';
import { Input } from '@mantine/core';
import { Search, CircleX } from 'lucide-react';
import { useFilterContext } from '@gettactic/components/src/controls/FilterContext/FilterContext';

export type FilterInputHandle = {
  resetFilter: () => void;
};

const FilterInputComponent: ForwardRefRenderFunction<FilterInputHandle> = (
  props,
  ref
) => {
  const {
    filter: locationFilter,
    setFilter: setLocationFilter,
    resetFilter: resetLocationFilter
  } = useFilterContext();

  useImperativeHandle(ref, () => ({
    resetFilter: resetLocationFilter
  }));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setLocationFilter(value);
  };

  return (
    <Input
      placeholder="Search"
      value={locationFilter}
      onChange={handleChange}
      icon={<Search className="h-4 w-4 text-gray-500" />}
      rightSection={
        locationFilter && (
          <CircleX
            onClick={resetLocationFilter}
            className="h-4 w-4 text-gray-700"
          />
        )
      }
      styles={{
        input: { paddingLeft: '2rem' }
      }}
    />
  );
};

export const FilterInput = forwardRef(FilterInputComponent);
