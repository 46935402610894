import { CalendarXMark } from '@/components/basic/icons/CalendarXMark';
import { WorkingLocationPresenceIcon } from '@/components/dashboard/WorkingLocationPresenceIcon';
import {
  RemotePolicy,
  useApplicableRemotePolicy
} from '@/hooks/useApplicablePolicies';
import { classNames } from '@/lib/classNames';
import {
  isRBFeature,
  isWorkingLocationsWithOOOFeaturesEnabled
} from '@/lib/featureFlags';
import {
  IWorkingLocation,
  WL_NOT_AVAILABLE,
  WL_PRESENCE_OOO,
  WL_PRESENCE_REMOTE
} from '@gettactic/api';
import {
  FilterProvider,
  useFilterContext
} from '@gettactic/components/src/controls/FilterContext/FilterContext';
import { LabelWithHighlight } from '@gettactic/components/src/data-display/labels/LabelWithHighlight/LabelWithHighlight';
import { useDivisionsMenuQuery } from '@gettactic/hooks';
import { useAuthenticated } from '@gettactic/hooks';
import {
  CheckIcon,
  HomeIcon,
  OfficeBuildingIcon,
  PlusIcon
} from '@heroicons/react/outline';
import { Menu, ScrollArea } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useRef, useState } from 'react';
import { HiNoSymbol } from 'react-icons/hi2';
import { FilterInput, FilterInputHandle } from './FilterInput';
import { RenderDivisionsMenu } from './RenderDivisionsMenu';

type SelectorType = 'select' | 'dashboard' | 'default';

type Props = {
  type: SelectorType;
  date?: string;
  value: string;
  fullLocation?: IWorkingLocation | null;
  setValue: (x: string) => void;
  readonly?: boolean;
  officesOnly?: boolean;
};
type LocationOptionType = {
  label: string;
  value: string;
  title: string;
};

type LocationOptionsType = LocationOptionType[];

export function WorkingLocationSelector({
  type,
  value,
  setValue,
  readonly = false,
  fullLocation = null,
  date,
  officesOnly = false
}: Props) {
  const {
    userContext: { authenticatedUser, deviceTz }
  } = useAuthenticated();
  const divisions = useDivisionsMenuQuery();
  const divisionsTree = divisions.data?.tree;
  const remotePolicy = useApplicableRemotePolicy(
    { tz: deviceTz, start: date },
    {
      enabled:
        type === 'dashboard' &&
        !!isWorkingLocationsWithOOOFeaturesEnabled(authenticatedUser),
      keepPreviousData: false
    }
  );
  const offices = authenticatedUser.offices?.offices ?? [];
  const [opened, setOpened] = useState(false);
  const officeLocationOptions: LocationOptionsType = offices.map((x) => ({
    label: x.name,
    value: x.id,
    title: x.name
  }));
  const locationOptions = officeLocationOptions.concat([
    { label: 'No Working Location', value: '', title: 'No Working Location' },
    { label: 'Remote', value: WL_PRESENCE_REMOTE, title: 'Remote' },
    { label: 'OOO', value: WL_PRESENCE_OOO, title: 'Out of Office' },
    { label: 'N/A', value: WL_NOT_AVAILABLE, title: 'N/A' }
  ]);
  const isOOORemote =
    fullLocation &&
    fullLocation.location === WL_PRESENCE_OOO &&
    fullLocation.policy_type === 'remote';
  const currentLocation = isOOORemote
    ? locationOptions.find((x) => x.value === WL_PRESENCE_REMOTE)
    : locationOptions.find((x) => x.value === value);

  if (!currentLocation) {
    console.warn('Warning location not found', currentLocation);
    return <>{currentLocation}</>;
  }
  const singleOffice = offices.length === 1;

  const filterRef = useRef<FilterInputHandle>(null);

  const handleSelection = (value: string) => {
    setValue(value);
    filterRef.current?.resetFilter();
  };

  return (
    <div className="relative w-full">
      <Menu
        opened={!readonly ? opened : false}
        onChange={setOpened}
        shadow={type === 'dashboard' ? 'md' : ''}
        width="target"
      >
        <Menu.Target>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className={classNames(
              readonly
                ? 'cursor-default'
                : 'cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2',
              'inline-flex items-center justify-between py-2',
              type === 'dashboard' &&
                'w-full rounded-full border border-transparent bg-white font-medium text-base text-secondary drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] ',
              type === 'select' &&
                'w-full rounded-md border bg-white font-medium text-base text-secondary',
              type === 'default'
                ? 'w-full rounded-md border-2 border-gray-200 bg-white pr-0 pl-2 font-medium text-base text-secondary lg:pr-0 lg:pl-2'
                : ' px-2 lg:px-5 '
            )}
          >
            <span
              title={currentLocation.title}
              className={classNames(
                'flex flex-nowrap items-center space-x-3 overflow-hidden whitespace-nowrap',
                !readonly &&
                  type === 'default' &&
                  'w-full border-gray-300 border-r'
              )}
            >
              <span>
                <WorkingLocationPresenceIcon
                  workingLocationId={currentLocation.value}
                  fullLocation={fullLocation}
                />
              </span>
              <span className="overflow-hidden text-ellipsis whitespace-nowrap pr-2">
                {currentLocation.label}
              </span>
            </span>

            {!readonly ? (
              <span
                className={classNames(
                  !readonly &&
                    type === 'default' &&
                    'px-[9px] text-gray-300 hover:text-gray-700'
                )}
              >
                <IconChevronDown
                  size={!readonly && type === 'default' ? 18 : 16}
                />
              </span>
            ) : null}
          </button>
        </Menu.Target>

        <Menu.Dropdown>
          <FilterProvider>
            <FilterInput ref={filterRef} />
            <ScrollArea
              style={{
                height: 'calc(100vh - 300px)'
              }}
              offsetScrollbars
              className="h-content max-h-60"
            >
              {!singleOffice && divisionsTree ? (
                <>
                  <RenderDivisionsMenu
                    tree={divisionsTree}
                    setValue={handleSelection}
                    value={value}
                    level={1}
                  />
                </>
              ) : (
                <OfficeList
                  value={value}
                  officeLocationOptions={officeLocationOptions}
                  handleSelection={handleSelection}
                />
              )}

              {officesOnly ? null : type === 'select' ? (
                <>
                  {' '}
                  {
                    // we only want to show the remote if the user has no restrictive policy assigned
                    !remotePolicy || remotePolicy.allotted === null ? (
                      <Menu.Item
                        onClick={() => handleSelection(WL_PRESENCE_REMOTE)}
                        icon={<HomeIcon className="h-5 w-5 flex-none" />}
                        rightSection={<RemotePolicyLabelRequired />}
                      >
                        <div className="flex items-center space-x-1">
                          <span>Remote</span> <RemotePolicyLabelAllotted />
                        </div>
                      </Menu.Item>
                    ) : null
                  }
                  <Menu.Item
                    onClick={() => handleSelection(WL_NOT_AVAILABLE)}
                    icon={<HiNoSymbol className="h-5 w-5 flex-none" />}
                  >
                    N/A
                  </Menu.Item>
                </>
              ) : (
                <>
                  {!isRBFeature(authenticatedUser) ? (
                    <Menu.Item
                      onClick={() => handleSelection(WL_PRESENCE_OOO)}
                      icon={<CalendarXMark className="h-5 w-5 flex-none" />}
                    >
                      OOO
                    </Menu.Item>
                  ) : null}

                  <Menu.Item
                    disabled={
                      !!(remotePolicy?.available && remotePolicy.available <= 0)
                    }
                    onClick={() => handleSelection(WL_PRESENCE_REMOTE)}
                    icon={<HomeIcon className="h-5 w-5 flex-none" />}
                    rightSection={<RemotePolicyLabelRequired />}
                  >
                    <div className="flex items-center space-x-1">
                      <span>Remote</span>{' '}
                      <RemotePolicyLabelAllotted
                        disabled={
                          !!(
                            remotePolicy?.available &&
                            remotePolicy.available <= 0
                          )
                        }
                      />
                    </div>
                  </Menu.Item>
                </>
              )}
            </ScrollArea>
          </FilterProvider>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}

const OfficeList = ({
  officeLocationOptions,
  handleSelection,
  value
}: {
  officeLocationOptions: LocationOptionsType;
  handleSelection: (value: string) => void;
  value: string;
}) => {
  const { filter: locationFilter } = useFilterContext();
  return (
    <>
      {officeLocationOptions.map((office: LocationOptionType) => (
        <Menu.Item
          key={`offi_${office.value}`}
          icon={<OfficeBuildingIcon className="h-5 w-5" />}
          onClick={() => {
            handleSelection(office.value);
          }}
          rightSection={
            office.value === value ? (
              <CheckIcon className="ml-1 h-5 w-5 text-primary" />
            ) : null
          }
        >
          <LabelWithHighlight label={office.label} highlight={locationFilter} />
        </Menu.Item>
      ))}
    </>
  );
};

const RemotePolicyLabelRequired = ({
  remotePolicy,
  type
}: {
  remotePolicy?: RemotePolicy | null;
  type?: SelectorType;
}) => {
  if (type !== 'dashboard' || !remotePolicy) {
    return null;
  }
  const approval = remotePolicy.policy.is_approvable;
  return <span>{approval ? <PlusIcon className="h-5 w-5" /> : null}</span>;
};

const RemotePolicyLabelAllotted = ({
  disabled = false,
  remotePolicy,
  type
}: {
  remotePolicy?: RemotePolicy | null;
  type?: SelectorType;
  disabled?: boolean;
}) => {
  if (
    type !== 'dashboard' ||
    !remotePolicy ||
    remotePolicy.policy.allotted === null
  ) {
    return null;
  }
  return (
    <span className="flex items-center space-x-2">
      <span
        className={classNames(
          'rounded-full px-2 py-1 font-bold',
          disabled
            ? 'bg-green-100 text-green-300'
            : 'bg-green-100 text-green-700'
        )}
      >
        {remotePolicy?.available && remotePolicy.available < 0
          ? 0
          : remotePolicy.available}
      </span>
      <span className="hidden">({remotePolicy.policy.renewal})</span>
    </span>
  );
};
