import React from 'react';

export const CrossIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.9394 7.99908L2.46973 3.52941L3.53039 2.46875L8.00006 6.93842L12.4697 2.46875L13.5304 3.52941L9.06072 7.99908L13.5304 12.4688L12.4697 13.5294L8.00006 9.05974L3.53039 13.5294L2.46973 12.4688L6.9394 7.99908Z"
      fill="#161C22"
    />
  </svg>
);
