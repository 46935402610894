import React from 'react';

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const LabelWithHighlight = ({
  label,
  highlight
}: {
  label: string;
  highlight: string | undefined;
}) => {
  const escapedHighlight = React.useMemo(() => {
    // Trim the highlight to avoid unwanted spaces
    const cleanedHighlight = (highlight || '').trim();
    if (cleanedHighlight.length === 0) {
      return cleanedHighlight;
    }
    return escapeRegExp(cleanedHighlight);
  }, [highlight]);

  if (!escapedHighlight || escapedHighlight.length === 0) {
    return <span>{label}</span>;
  }

  // Create the regex using the escaped highlight
  const parts = label.split(new RegExp(`(${escapedHighlight})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === escapedHighlight.toLowerCase() ? (
          <span key={`highlighted_${index}_${part}`} className="font-semibold">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  );
};
