export const CircleCheckEmpty: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.75"
      y="0.75"
      width="14.5"
      height="14.5"
      rx="7.25"
      stroke="#161C22"
      stroke-width="1.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2786 5.53017L7.98836 10.8226C7.30488 11.5064 6.19644 11.5064 5.51297 10.8226L2.72168 8.03017L3.78256 6.96973L6.57385 9.76218C6.67149 9.85986 6.82984 9.85986 6.92748 9.76218L12.2177 4.46973L13.2786 5.53017Z"
      fill="#161C22"
    />
  </svg>
);
