export const CircleCheckFilled: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.200195" width="16" height="16" rx="8" fill="#161C22" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4788 5.53017L8.18855 10.8226C7.50508 11.5064 6.39664 11.5064 5.71316 10.8226L2.92188 8.03017L3.98276 6.96973L6.77404 9.76218C6.87168 9.85986 7.03003 9.85986 7.12767 9.76218L12.4179 4.46973L13.4788 5.53017Z"
      fill="white"
    />
  </svg>
);
